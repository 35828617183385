import React from 'react';

import MendiKontaketakBlockView from './View';
export const MendiKontaketakBlockEdit = ({ data }) => {
  return (
    <>
      <MendiKontaketakBlockView data={data} />
    </>
  );
};
